import React from 'react';
import {Box, Card, CardContent, Container, Grid, Link, Typography} from "@mui/material";
import palette from "../../helpers/palette";

export default function () {
    return (
        <Box id="footer" sx={{bgcolor: palette.background4, py: 6}}>
            <Container maxWidth="lg">
                <Typography variant="body2" color="text.secondary" align="center">
                    © 2024 ORYX Medical AI. All rights reserved.
                </Typography>
                <Typography variant="body2" color="text.secondary" align="center" sx={{mt: 1}}>
                    <Link
                        href="../../termsOfService/Terms Of Service.pdf"
                        color="inherit"
                        sx={{mx: 1}}
                        download="terms-of-service.pdf"
                    >
                        Terms of Service
                    </Link>
                    {/*<Link href="#" color="inherit" sx={{mx: 1}}>*/}
                    {/*    Contact Us*/}
                    {/*</Link>*/}
                </Typography>
            </Container>
        </Box>
    )
}