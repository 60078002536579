import Particles, {initParticlesEngine} from "@tsparticles/react";
import type {ISourceOptions} from "@tsparticles/engine";
import palette from "./palette";

export const particleOptions: ISourceOptions = {
    fullScreen: {
        enable: true
    },
    fpsLimit: 60,
    particles: {
        number: {
            value: 50
        },
        size: {
            value: 400,
        },
        color: {
            value: [
                palette.primary,
                palette.primary2,
                palette.primary3,
            ]
        },
        shape: {
            type: "circle"
        },
        opacity: {
            value: 0.5
        },
        move: {
            enable: true,
            speed: 5,
            direction: "top",
            outModes: {
                default: "out",
                top: "destroy",
                bottom: "none"
            }
        },
    },
    detectRetina: false,
};
