import React from 'react';
import {Box} from '@mui/material';
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import KeyFeatures from "./components/KeyFeatures";
import HowItWorks from "./components/HowItWorks";
import Testimonials from "./components/Testimonials";
import GetInTouch from "./components/GetInTouch";
import Footer from "./components/Footer";
import Disciplines from "./components/Disciplines";
import MeetUs from "./components/MeetUs";
import Hipaa from "./components/Hipaa";

export default function () {
    return (
        <Box sx={{flexGrow: 1}}>
            <Header/>
            <HeroSection/>
            <br/>
            <KeyFeatures/>
            <br/>
            <Disciplines/>
            <br/>
            <HowItWorks/>
            <br/>
            <Hipaa/>
            <br/>
            <MeetUs/>
            <br/>
            <GetInTouch/>
            <Footer/>

        </Box>
    );
}