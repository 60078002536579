import React from 'react';
import {Button, Card, Container, Typography} from "@mui/material";
import palette from "../../helpers/palette";
import fonts from "../../helpers/fonts";
import Grid from "@mui/material/Grid2";
import Saad from "../../images/Saad.jpg";
import Maha from "../../images/Maha.jpg";

export default function () {

    return (
        <Container id="meet-us" sx={{py: 8}} style={{backgroundColor: palette.background4, borderRadius: 20}}>
            <Typography
                variant="h3"
                align="center"
                gutterBottom
                style={{color: palette.background3, fontFamily: fonts.secondary, fontWeight: 400, fontSize: 40}}
            >
                Meet the team powering your AI-driven practice.
            </Typography>
            <Grid container spacing={4}>
                <Grid size={{xs: 12, sm: 6, md: 6}}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
                        <img
                            src={Maha}
                            style={{ width: '100px', height: '100px', borderRadius: '50%', marginBottom: '16px' }}
                        />
                        <Typography
                            variant="h6"
                            style={{color: palette.background3, fontFamily: fonts.secondary}}
                        >
                            Maha Khamlichi
                        </Typography>
                        <Button
                            variant="contained"
                            href="https://www.linkedin.com/in/maha-khamlichi-5284ba186/"
                            target="_blank"
                            sx={{
                                backgroundColor: palette.primary3,
                                color: palette.background,
                                borderRadius: '20px',
                                padding: '10px 20px',
                                '&:hover': {
                                    backgroundColor: palette.accent3,
                                    color: palette.background3
                                }
                            }}
                        >
                            View LinkedIn
                        </Button>
                    </Card>
                </Grid>
                <Grid size={{xs: 12, sm: 6, md: 6}}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
                        <img
                            src={Saad}
                            style={{ width: '100px', height: '100px', borderRadius: '50%', marginBottom: '16px' }}
                        />
                        <Typography
                            variant="h6"
                            style={{color: palette.background3, fontFamily: fonts.secondary}}
                        >
                            Saad Moussadek
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            href="https://www.linkedin.com/in/saad-moussadek-63286b13a/"
                            target="_blank"
                            sx={{
                                backgroundColor: palette.primary3,
                                color: palette.background,
                                borderRadius: '20px',
                                padding: '10px 20px',
                                '&:hover': {
                                    backgroundColor: palette.accent3,
                                    color: palette.background3
                                }
                            }}
                        >
                            View LinkedIn
                        </Button>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}