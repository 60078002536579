import React, {useEffect, useRef, useState} from 'react';
import { Box, Button, Container, Typography } from "@mui/material";
import palette, { rgba } from "../../helpers/palette";
import fonts from "../../helpers/fonts";
import Particles, {initParticlesEngine} from "@tsparticles/react";
import {loadSlim} from "@tsparticles/slim";
import {particleOptions} from "../../helpers/particleOptions";

export default function HeroSection() {
    const [ init, setInit ] = useState(false);

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);


    return (
        <Box id="hero-section" sx={{ position: 'relative', height: 500, overflow: 'hidden' }}>
            <div style={{
                position: "relative",
                zIndex: -1
            }}>
                { init && <Particles
                    style={{
                        height: 500,
                        position: "relative",
                        zIndex: -1,
                    }}
                    options={particleOptions}
                />
                }
            </div>
            <Container
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    position: 'relative',
                    zIndex: 1,
                }}
            >
                <Typography
                    gutterBottom
                    style={{color: palette.background3, fontFamily: fonts.secondary, fontWeight: 600, fontSize: 55, marginBottom: 0}}
                >
                    Redefining Medical Excellence with AI
                </Typography>
                <Typography
                    variant="h5"
                    style={{color: palette.background3, fontFamily: fonts.secondary, fontWeight: 500}}
                >
                    Meet <strong style={{color:palette.accent3}}>Cherry</strong>, your intelligent medical companion
                </Typography>
                <br/>
                <Button
                    variant="contained"
                    color="primary"
                    href="#get-in-touch"
                    sx={{
                        mb: 2,
                        backgroundColor: palette.primary3,
                        color: palette.background,
                        borderRadius: '20px',
                        padding: '10px 20px',
                        '&:hover': {
                            backgroundColor: palette.accent3,
                            color: palette.background3
                        }
                    }}
                >
                    Get Started
                </Button>
            </Container>
        </Box>
    );
}
