import React from 'react';
import {AppBar, Button, Toolbar, Typography} from "@mui/material";
import palette from "../../helpers/palette";
import fonts from "../../helpers/fonts";
import oryxLogo from "../../oryxLogo/palette-no-bg.png";

export default function () {
    const buttonFontSize = 16;
    return (
        <AppBar position="static" style={{backgroundColor: palette.primary}}>
            <Toolbar>
                <img
                    src={oryxLogo}
                    style={{width: 200, marginRight: 7}}
                />
            </Toolbar>
        </AppBar>
    )
}