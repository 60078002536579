export default {
    primary: "#97e1d9",
    primary2: "#d2f6f3",
    primary3: "#1c8c82",

    accent: "#81d285",
    accent2: "#fad05a",
    accent3: "#FF5F6D",

    supporting: "#A29BFE",
    supporting2: "#ea6bb7",
    supporting3: "#f3a14f",

    background: "#fff",
    background2: "#00000014",
    background3: "#494949",
    background4: "#f5f5f5"
}

export const rgba = {
    primary: "rgba(151,225,217,1)",
    primary2: "rgba(210,246,243,1)",
    primary3: "rgba(28,140,130,1)",

    accent: "rgba(129,210,133,1)",
    accent2: "rgba(250,208,90,1)",
    accent3: "rgba(255,95,109,1)",

    supporting: "rgba(162,155,254,1)",
    supporting2: "rgba(234,107,183,1)",
    supporting3: "rgba(243,161,79,1)",

    background: "rgba(255,255,255,1)",
    background2: "rgba(0,0,0,0.08)",
    background3: "rgba(73,73,73,1)",
    background4: "rgba(245,245,245,1)"
}