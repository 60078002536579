import React from 'react';
import {Box, Container, Typography} from "@mui/material";
import Grid from '@mui/material/Grid2';
import palette from "../../helpers/palette";
import fonts from "../../helpers/fonts";
import hipaaLogo from "../../images/hipaa_logo-removebg-preview.png";

export default function () {
    return (
        <Container id="hipaa" sx={{py: 8}} style={{backgroundColor: palette.background4, borderRadius: 20}}>
            <Typography
                variant="h3"
                align="center"
                gutterBottom
                style={{color: palette.background3, fontFamily: fonts.secondary, fontWeight: 400, fontSize: 40}}
            >
                Towards HIPAA Compliance
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                <img
                    src={hipaaLogo}
                    style={{width: 250}}
                />
            </Box>
            <Typography
                variant="h6"
                align="center"
                gutterBottom
                style={{color: palette.background3, fontFamily: fonts.primary, fontSize: 25}}
            >
                We are actively working towards achieving full HIPAA compliance, ensuring the highest standards of
                patient data privacy and security. Your trust and confidentiality are our top priorities.
            </Typography>
        </Container>
    )
}