import React, {useState} from "react";
import {
    Box,
    Button,
    Card,
    Container,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import palette from "../../helpers/palette";
import fonts from "../../helpers/fonts";
import AnimatedPin from "./AnimatedPin";


export default function () {
    const initialMessage = 'Hello, I\'d like to power my practice with AI. Can you give me a demo?';

    return (
        <Box id="get-in-touch"
             sx={{bgcolor: palette.primary, color: palette.background3, py: 8, textAlign: "center"}}>
            <Container>
                <Card sx={{
                    width: { xs: "90%", md: "50%" },
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 2,
                    mx: "auto"
                }}>
                    <Typography
                        variant="h6"
                        align="center"
                        gutterBottom
                        style={{color: palette.background3, fontFamily: fonts.primary, fontSize: 30}}
                    >
                        Get in touch now!
                    </Typography>
                    <Typography
                        variant="h6"
                        align="center"
                        gutterBottom
                        style={{color: palette.background3, fontFamily: fonts.primary, fontSize: 20}}
                    >
                        An engineer can meet you within 48 hours at your practice to introduce you to
                        <strong style={{color: palette.accent3}}> Cherry</strong> for free — Experience the
                        innovation directly from the experts who designed it, not a sales team.
                    </Typography>
                    <br/>
                    <Typography
                        variant="h6"
                        gutterBottom
                        style={{color: palette.background3, fontFamily: fonts.primary, fontSize: 20}}
                    >
                        <AnimatedPin/>
                        ORYX Medical AI is available in <strong style={{color: palette.accent3}}> Dubai,
                        UAE</strong>
                    </Typography>
                    <br/>

                    <form
                        name="contact"
                        method="POST"
                        data-netlify="true"
                    >
                        <input type="hidden" name="form-name" value="contact"/>
                        <TextField name="email" fullWidth label="Email Address" variant="outlined"
                                   style={{marginBottom: 15}}/>
                        <TextField name="fullName" fullWidth label="Full Name" variant="outlined"
                                   style={{marginBottom: 15}}/>
                        <TextField name="medicalField" fullWidth select label="Field" variant="outlined"
                                   style={{marginBottom: 15}}>
                            <MenuItem value="Dentist">Dentist</MenuItem>
                            <MenuItem value="GP">GP</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </TextField>
                        <TextField name="message" fullWidth label="Message" variant="outlined"
                                   defaultValue={initialMessage} style={{marginBottom: 15}} multiline rows={4}/>
                        <Button
                            id="send-button"
                            type="submit"
                            variant="contained"
                            onClick={() => window.alert("Thanks for getting in touch! We will get back to you within 48 hours.")}
                            sx={{
                                backgroundColor: palette.primary3,
                                color: palette.background,
                                borderRadius: "20px",
                                padding: "10px 20px",
                                "&:hover": {
                                    backgroundColor: palette.accent3,
                                    color: palette.background3
                                }
                            }}>
                            Send
                        </Button>
                    </form>
                </Card>
            </Container>
        </Box>
    )
}