import React from 'react';
import {Box, Container, Typography} from "@mui/material";
import Grid from '@mui/material/Grid2';
import palette from "../../helpers/palette";
import fonts from "../../helpers/fonts";

export default function () {
    return (
        <Container id="how-it-works" sx={{py: 8}} style={{backgroundColor: palette.background4, borderRadius: 20}}>
            <Typography
                variant="h3"
                align="center"
                gutterBottom
                style={{color: palette.background3, fontFamily: fonts.secondary, fontWeight: 400, fontSize: 40}}
            >
                AI-powered healthcare made simple — automating your practice from start to finish.
            </Typography>
            <Grid container spacing={4}>
                {[
                    {
                        step: 1,
                        title: "Create or Select Patient",
                        description: "Easily create or select patient profiles with voice commands."
                    },
                    {
                        step: 2,
                        title: "Consultation",
                        description: "Engage in consultations where Cherry listens and converse with you, and " +
                            "continuously updates the consultation dashboard."
                    },
                    {
                        step: 3,
                        title: "Consultation Recap",
                        description: "Receive a detailed recap featuring follow-ups, referrals, after-care " +
                            "recommendations, sent automatically."
                    },
                ].map((item, index) => (
                    <Grid key={index} size={{xs: 12, md: 4}}>
                        <Box sx={{textAlign: 'center'}}>
                            <Typography variant="h1" component="div" sx={{color: palette.primary3, mb: 2}}>
                                {item.step}
                            </Typography>
                            <Typography variant="h5" component="h3" gutterBottom>
                                {item.title}
                            </Typography>
                            <Typography>
                                {item.description}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}