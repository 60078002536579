import React from 'react';
import {SvgIcon} from '@mui/material';
import PinIcon from "../../images/pinIcon.png"

const AnimatedPin = () => (
    <>
        <img
            src={PinIcon}
            style={{animation: 'bounce 1s infinite', width: 30, marginRight: 7}}
        />
        <style>
            {`
        @keyframes bounce {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(5px); }
        }
      `}
        </style>
    </>
);

export default AnimatedPin;
