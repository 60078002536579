import React from 'react';
import {Box, Card, CardContent, Container, Typography} from "@mui/material";
import Grid from '@mui/material/Grid2';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SummarizeIcon from '@mui/icons-material/Summarize';
import palette from "../../helpers/palette";
import fonts from "../../helpers/fonts";
import ConsultationPreview from "../../images/consultation preview.png";

export default function () {
    const features = [
        {
            icon: <RecordVoiceOverIcon fontSize="large"/>,
            title: 'Hands-Free Interaction',
            description: 'Fully voice-activated, no clicks needed for seamless assistance.'
        },
        {
            icon: <AutoGraphIcon fontSize="large"/>,
            title: 'Real Time AI Insights',
            description: 'Immediate, tailored recommendations for diagnostics, treatments, and procedures.'
        },
        {
            icon: <EditNoteIcon fontSize="large"/>,
            title: 'Intelligent Scribing',
            description: 'Effortlessly captures and transcribes consultations in real time, ensuring accurate documentation.'
        },
        {
            icon: <SummarizeIcon fontSize="large"/>,
            title: 'Consultation Recaps',
            description: 'Automatically generates detailed consultation summaries and sends them to your chosen recipients.'
        }
    ];

    return (
        <Container id="key-features" sx={{ py: 8 }} style={{ backgroundColor: palette.background4, borderRadius: 20 }}>
            <Typography
                variant="h3"
                align="center"
                gutterBottom
                style={{color: palette.background3, fontFamily: fonts.secondary, fontWeight: 400, fontSize: 45}}
            >
                Your practice, perfected.
            </Typography>
            <Grid container spacing={4} alignItems="stretch">
                <Grid size={{xs: 12, sm: 5, md: 5}}
                      sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <img src={ConsultationPreview} style={{width: "100%", borderRadius: 5}}/>
                </Grid>
                <Grid size={{xs: 12, sm: 7, md: 7}}>  {/* 'item' instead of 'size' */}
                    <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Grid container spacing={4}>
                            {features.map((feature, index) => (
                                <Grid key={index} size={{xs: 12, sm: 6, md: 6}}>
                                    <Card
                                        sx={{
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            transition: 'transform 0.3s ease-in-out',
                                            '&:hover': {
                                                transform: 'scale(1.1)',
                                            },
                                        }}
                                    >
                                        <CardContent sx={{flexGrow: 1, textAlign: 'center'}}>
                                            <Box sx={{color: palette.primary3, mb: 2}}>{feature.icon}</Box>
                                            <Typography gutterBottom variant="h5">
                                                {feature.title}
                                            </Typography>
                                            <Typography>
                                                {feature.description}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
}
